import {App, ConfigProvider} from 'antd'
import ruRU from 'antd/locale/ru_RU'
import enUS from 'antd/locale/en_US'
import {initializeApp} from 'firebase/app'
import {createContext, useEffect, useState, useCallback} from 'react'
import ReactDOM from 'react-dom/client'
import {Navigate, Outlet, RouteObject, RouterProvider, createBrowserRouter, useLocation, matchRoutes} from 'react-router-dom'
import {initializeFaro, createReactRouterV6DataOptions, ReactIntegration, getWebInstrumentations, withFaroRouterInstrumentation} from '@grafana/faro-react'
import {TracingInstrumentation} from '@grafana/faro-web-tracing'
import {ToastContainer} from 'react-toastify'
import {GlobalModal} from './components/modal/global/globalModal'
import modalService from "./components/modal/global/modal.service"
import AuthComponent from './pages/auth/auth'
import SchedulesListComponent from './pages/schedule'
import {ProductSetEditComponent} from "./pages/set/edit"
import ProductSetListComponent from './pages/set/list'
import {clientRoutes} from './routers/client'
import {configRoutes} from './routers/configuration'
import {inventoryRoutes} from './routers/inventory'
import {orderRoutes} from './routers/order'
import {settingRoutes} from "./routers/settings"
import {websiteRoutes} from "./routers/website"
import {Spotlight} from "./components/onboarding/spotlight"
import {I18nextProvider, useTranslation} from "react-i18next"
import {TenantSubscriptionModal} from "./components/modal/subscription"
import {CustomModal} from "./components/modal/global/customModal"
import {IntegrationComponent} from "./pages/integration"
import {SmsIntegrationComponent} from "./pages/integration/sms"
import {SettingConfig} from "./models/setting-config"
import {ManagerConstantMapModel, ManagerConstantModel} from "./models/manager/constants"
import {GeolocationComponent, DeviceListComponent, DeviceTripComponent, TripListComponent} from "./pages/geolocation"
import {initAmplitude, trackAmplitudeData} from "./services/amplitude"
import subscriptionModalService, {SubscriptionModalConfig} from "./services/subscription.service"
import integrationService from "./services/integration"
import settingService from "./services/settings.service"
import appService from "./services/app.service"
import moment from "moment-timezone"
import i18n from "./i18n"
import Main from './main'
import 'src/interceptor'
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/ru'
import './index.scss'
import {IntegrationModel} from "./models/integration"
import {WazzupFrameComponent, WazzupLandingComponent} from "./pages/integration/wazzup"
import {EgovIntegrationLanding} from "./pages/integration/egov"
import {GPSIntegrationLanding} from "./pages/integration/gps"
import tenantService, {TenantModel} from "./services/tenant.service"
import {connect, disconnect} from "./services/cent"
import {headerHeight$} from "./components/header"
import ProductPublishedList from "./pages/website/product-published/list";
import authService, {ProfileModel} from "./services/auth.service"

moment.tz.setDefault("Asia/Tashkent")
moment.locale('ru')

const prod = process.env.NODE_ENV === 'production'

if (prod) {
  const firebaseConfig = {
    apiKey: 'AIzaSyAhiRARLU6Tf43VJyeQe1gLvGORkIwJxj0',
    authDomain: 'yume-admin.firebaseapp.com',
    projectId: 'yume-admin',
    storageBucket: 'yume-admin.appspot.com',
    messagingSenderId: '195215475313',
    appId: '1:195215475313:web:458baeb90d54a23cd091d0',
  }

  initializeApp(firebaseConfig)

  initializeFaro({
    url: 'https://faro-collector-prod-au-southeast-0.grafana.net/collect/459ef04f5cf816540fb573c9d45b3bad',
    app: {
      name: 'yume.cloud',
      version: '1.0.0',
      environment: process.env.NODE_ENV
    },

    instrumentations: [
      ...getWebInstrumentations(),
      new TracingInstrumentation(),
      new ReactIntegration({
        router: createReactRouterV6DataOptions({
          matchRoutes,
        }),
      })
    ],
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))

interface ClientContextModel {
  tenant: TenantModel
  settings: SettingConfig
  constants: ManagerConstantModel
  constantsMap: ManagerConstantMapModel
  profile: ProfileModel,
  integrationMap: Record<string, IntegrationModel>
  isMobile: boolean
  headerHeight: number
  mobileMenu: boolean
  hideMobileMenu: () => void
  showMobileMenu: () => void
}

export const ConfigContext = createContext<ClientContextModel>(undefined)

function Application({children}) {
  const location = useLocation()
  const [tenant, setTenant] = useState<TenantModel>()
  const [settings, setSettings] = useState<SettingConfig>()
  const [constants, setConstants] = useState<ManagerConstantModel>()
  const [constantsMap, setConstantsMap] = useState<ManagerConstantMapModel>()
  const [integrationMap, setIntegrationMap] = useState<Record<string, IntegrationModel>>({})
  const [subs, setSubs] = useState<SubscriptionModalConfig>({show: false, type: null})
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [profile, setProfile] = useState<ProfileModel>()
  const [headerHeight, setHeaderHeight] = useState<number>(0)
  const [mobileMenu, setMobileMenu] = useState<boolean>(true)
  const hideMobileMenu = useCallback(() => setMobileMenu(false), [])
  const showMobileMenu = useCallback(() => setMobileMenu(true), [])

  useEffect(() => {
    if (!location) return
    if (!location.pathname) return
    trackAmplitudeData(
      `View ${location.pathname.replace(/\d+/g, ":id")}`,
      {pathname: location.pathname}
    )
    modalService.closeModal()
  }, [location.pathname])

  useEffect(() => {
    const sub = subscriptionModalService.show$.subscribe(setSubs)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = tenantService.tenant$.subscribe(setTenant)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = settingService.config$.subscribe(setSettings)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = appService.constants$.subscribe((c) => {
      setConstants(c)
      setConstantsMap({
        CLIENT_TICKS: Object.fromEntries(c.CLIENT_TICKS.map(o => [o.id, o])),
        CLIENT_ATTRACTION_METHOD: Object.fromEntries(c.CLIENT_ATTRACTION_METHOD.map(o => [o.id, o])),
        CLIENT_TYPE: Object.fromEntries(c.CLIENT_TYPE.map(o => [o.id, o])),
        SERVICE_TYPE: Object.fromEntries(c.SERVICE_TYPE.map(o => [o.id, o])),
        INVENTORY_GROUP_TYPE: Object.fromEntries(c.INVENTORY_GROUP_TYPE.map(o => [o.id, o])),
        INVENTORY_CATEGORIES: Object.fromEntries(c.INVENTORY_CATEGORIES.map(o => [o.id, o])),
        INVENTORY_STATUS: Object.fromEntries(c.INVENTORY_STATUS.map(o => [o.id, o])),
        INVENTORY_STATE_STATUS: Object.fromEntries(c.INVENTORY_STATE_STATUS.map(o => [o.id, o])),
        TARIF_TIME_PERIODS: Object.fromEntries(c.TARIF_TIME_PERIODS.map(o => [o.id, o])),
        ORDER_REQUEST_STATUS: Object.fromEntries(c.ORDER_REQUEST_STATUS.map(o => [o.id, o])),
        ORDER_PAYMENT_STATUS: Object.fromEntries(c.ORDER_PAYMENT_STATUS.map(o => [o.id, o])),
        PAYMENT_TYPES: Object.fromEntries(c.PAYMENT_TYPES.map(o => [o.id, o])),
        RENTAL_POINTS: Object.fromEntries(c.RENTAL_POINTS.map(o => [o.id, o])),
        PASSPORT_ISSUERS: Object.fromEntries(c.PASSPORT_ISSUERS.map(o => [o.id, o])),
        DISCOUNT_TYPE: Object.fromEntries(c.DISCOUNT_TYPE.map(o => [o.id, o])),
        DISCOUNT_CALCULATION_TYPE: Object.fromEntries(c.DISCOUNT_CALCULATION_TYPE.map(o => [o.id, o])),
      })
    })
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = integrationService.integrations$.subscribe((integrations) => {
      setIntegrationMap(integrations.reduce((p, c) => ({...p, [c.code]: c}), {}))
    })
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const systemLang = localStorage.getItem('language')
    if (systemLang) {
      moment.locale(systemLang)
      i18n.changeLanguage(systemLang)
      return
    }
    if (settings && i18n.language !== settings.language) {
      moment.locale(settings.language)
      i18n.changeLanguage(settings.language)
    }
  }, [settings])

  useEffect(() => {
    initAmplitude()
  }, [])

  useEffect(() => {
    connect()
    return () => disconnect()
  }, [])

  useEffect(() => {
    const sub = headerHeight$.subscribe(setHeaderHeight)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = authService.profile$.subscribe(setProfile)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768)
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ConfigContext.Provider
      value={{
        tenant,
        settings,
        constants,
        constantsMap,
        profile,
        integrationMap,
        isMobile,
        mobileMenu,
        hideMobileMenu,
        showMobileMenu,
        headerHeight
      }}
    >
      {children}
      {tenant && tenant.demo && (
        <div className="fixed right-2 bottom-2 p-3 shadow rounded-lg z-[1000] cursor-pointer bg-primary" onClick={()=>tenantService.moveToProduction()}>
          Перейти на боевую версию
        </div>
      )}
      <CustomModal dialogClassName="subscription-modal" show={subs.show} onHide={() => setSubs({show: false, type: null})}>
        <TenantSubscriptionModal />
      </CustomModal>
    </ConfigContext.Provider >
  )
}

export const routes: RouteObject[] = [
  {
    path: '',
    element: (
      <Application>
        <Outlet />
        <Spotlight />
        <GlobalModal />
        <ToastContainer
          position="bottom-right"
          autoClose={3500}
          hideProgressBar={true}
          newestOnTop={true}
          draggable={false}
          theme="light"
        />
      </Application>
    ),
    children: [
      {
        path: '',
        element: (
          <Main>
            <Outlet />
          </Main>
        ),
        errorElement: (
          <Main>
            <div className="flex items-center justify-center p-5">
              <div className="mb-0 text-2xl font-medium p-5">Error</div>
            </div>
          </Main>
        ),
        children: [
          {path: '', element: <Navigate to="orders" relative="route" replace={false} />},
          {path: 'calendar', element: <SchedulesListComponent />},
          {
            path: 'geolocation',
            element: <GeolocationComponent />,
            children: [
              {
                path: '',
                element: <DeviceListComponent />
              },
              {
                path: ':deviceId/trips',
                element: <TripListComponent />,
                children: [
                  {path: ":tripId", element: <DeviceTripComponent />}
                ]
              }
            ]
          },

          // sets
          {path: 'inventory-sets', element: <ProductSetListComponent />},
          {path: 'inventory-sets/add', element: <ProductSetEditComponent mode="create" />},
          {path: 'inventory-sets/:id', element: <ProductSetEditComponent mode="edit" />},

          {path: 'integrations', element: <IntegrationComponent />},
          {path: 'integrations/sms', element: <SmsIntegrationComponent />},
          {path: 'integrations/gps', element: <GPSIntegrationLanding />},
          {path: 'integrations/wazzup', element: <WazzupLandingComponent />},
          {path: 'integrations/egov', element: <EgovIntegrationLanding />},
          {path: 'integrations/widget', element: <ProductPublishedList />},
          {path: 'integrations/*', element: <>not found</>},

          {path: 'wazzup', element: <WazzupFrameComponent />},

          ...websiteRoutes,
          ...orderRoutes,
          ...configRoutes,
          ...settingRoutes,
          ...clientRoutes,
          ...inventoryRoutes,
        ],
      },
      {
        path: 'auth',
        element: <AuthComponent />,
      },
    ],
  },
  {
    path: "*", element: ""
  }
]

const router = withFaroRouterInstrumentation(createBrowserRouter(routes))

const antLocale = {
  'ru': ruRU,
  'en': enUS
}

function ConfigWrapper() {
  const {i18n} = useTranslation()

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#000000',
          colorPrimaryBg: '#e2e2e2',
          fontSize: 14,
          borderRadius: 8,
          colorInfo: "#000000",
          lineHeight: 1.32,
          fontFamily: "-apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif;",
        },
        components: {
          Table: {},
          Checkbox: {
            controlInteractiveSize: 24,
            borderRadius: 6,
            borderRadiusLG: 6,
            borderRadiusXS: 6,
            borderRadiusSM: 6,
            colorBorder: 'var(--color-gray-200)',
            colorBgContainer: 'var(--color-white)',
            colorBgContainerDisabled: 'var(--color-gray-100)',
          },
        },
      }}
      locale={antLocale[i18n.language] || ruRU}
    >
      <App>
        <RouterProvider router={router} />
      </App>
    </ConfigProvider>
  )
}

root.render(
  <I18nextProvider i18n={i18n}>
    <ConfigWrapper />
  </I18nextProvider>
)