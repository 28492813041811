import {Link, useNavigate} from 'react-router-dom'
import {useContext, useEffect, useMemo, useRef, useState} from 'react'
import authService, {ProfileModel} from "src/services/auth.service"
import Icon from "src/components/shared/components/material-icon"
import orderService from "src/services/order-requests/order.service"
import workshiftService, {WorkshiftModel} from "src/services/workshift.service"
import WorkshiftTimer from "../workshift/workshift-timer"
import {firstValueFrom, switchMap} from "rxjs"
import {CurrentRentalPoint} from "./rental-point"
import {useTranslation} from 'react-i18next'
import {isNil} from "src/utils/isNil"
import {ConfigContext} from "src"
import clsx from "clsx"
import {trackAmplitudeData} from "src/services/amplitude"
import LinkWrapper from "./linkWrapper/linkWrapper"
import moment from "moment-timezone"
import subscriptionModalService from "src/services/subscription.service"
import {Popover} from "antd"
import NotificationPopUp from "./notification"
import './aside.scss'

const getClassName = (percent: number) => {
  if (percent < 20) {
    return {
      bar: 'bg-alert',
      text: 'color-red-300',
    }
  } else if (percent > 20 && percent < 80) {
    return {
      bar: 'bg-warn-400',
      text: 'color-warn-400',
    }
  } else {
    return {
      bar: 'bg-accept',
      text: 'color-green-500',
    }
  }
}

function SubscriptionAside({collapse}: {collapse: boolean}) {
  const {t} = useTranslation()
  const {tenant} = useContext(ConfigContext)

  const {_totalDays, _daysLeft, _percent, bar, text} = useMemo(() => {
    if (!tenant) return {_totalDays: 0, _daysLeft: 0, _percent: 0, bar: 'bg-gray-300', text: 'bg-accept'}

    const duration = moment(tenant.end_at).diff(tenant.start_at, 'days')
    const left = moment(tenant.end_at).diff(moment(), 'days')
    const percent = Math.max(100 * left / duration, 5)

    return {_totalDays: duration, _daysLeft: left, _percent: percent, ...getClassName(percent)}
  }, [tenant])

  return _totalDays === 14 && (
    <div className={collapse ? 'flex bg-gray-800 rounded-lg p-3 gap-4 items-center border border-gray-700' : 'flex flex-col bg-gray-800 rounded-lg p-3 gap-4 border border-gray-700'}>
      {!collapse && (
        <div className="text-sm font-semibold">
          {t('aside.limit')}
          <span className={`ml-1 ${text}`}>{`${_daysLeft}/${_totalDays}`}</span>
        </div>
      )}
      <div className="w-full h-[7px] relative overflow-hidden rounded-lg bg-gray-500">
        <div className={`h-full ${bar}`} style={{width: `${_percent}%`}}></div>
      </div>
      {!collapse && (
        <button
          className="btn btn-primary btn-color-white justify-start gap-1 font-semibold text-nowrap, text-xs"
          style={{padding: 10, overflow: 'hidden', width: '100%'}}
          onClick={() => subscriptionModalService.show$.next({show: true, type: null})}
        >
          {t('aside.update_plan')} <Icon icon="east" className="text-lg" />
        </button>
      )}
    </div>
  )
}

export function Aside({collapse, onCollapse}: {collapse: boolean; onCollapse: () => void}) {
  const scrollToRef = (ref: any) => ref.current?.scroll({top: 0, behavior: 'smooth'})
  const ref = useRef(null)
  const {settings, integrationMap} = useContext(ConfigContext)
  const [workshift, setWorkshift] = useState<WorkshiftModel>()
  const [profile, setProfile] = useState<ProfileModel>()
  const [opacity, setOpacity] = useState(0.3)
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const {t} = useTranslation()

  const createOrder = () => {
    firstValueFrom(
      authService.profile$.pipe(switchMap(profile => {
        trackAmplitudeData("Create Order")
        return orderService.post({rental_point: profile.points.length > 0 ? profile.points[0] : null}).then(res => navigate(`/orders/${res.id}/all`))
      }))
    )
  }

  const has_perms = (key: string) => profile && (profile.perms.some(perm => perm.includes(key)) || profile.is_superuser)

  useEffect(() => {
    const sub = authService.profile$.subscribe(setProfile)
    scrollToRef(ref)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = workshiftService.workshift$.subscribe(setWorkshift)
    return () => sub.unsubscribe()
  }, [])

  const useWorkshift = settings && settings.workshift
  const workshiftStarted = workshift && !isNil(workshift.start_at)
  const is_superuser = profile && profile.is_superuser

  const contentAccess = useWorkshift ? workshiftStarted || is_superuser : true
  const handleLinkClick = () => {
    setPopoverVisible(!popoverVisible)
  }
  const hide = () => {
    setOpen(false)
  }
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  return (
    <aside className="scrollbar-hide h-screen" ref={ref}>

      <div className="company-icon overflow-hidden">
        {collapse ? (
          <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.28687 24H2.57167L5.27352 17.842L0 4.80371H3.94876L7.13941 14.1294L10.6211 4.80371H14.5953L6.28687 24Z" fill="white" />
            <path d="M15.9781 19.1214C16.9388 19.1214 17.7176 18.3426 17.7176 17.3819C17.7176 16.4212 16.9388 15.6423 15.9781 15.6423C15.0173 15.6423 14.2385 16.4212 14.2385 17.3819C14.2385 18.3426 15.0173 19.1214 15.9781 19.1214Z" fill="#A165FD" />
          </svg>
        ) : (
          <svg width="145" height="24" viewBox="0 0 145 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M86.6066 19.3075C82.506 19.3075 79.2043 16.0824 79.2043 12.0571C79.2043 7.98198 82.4052 4.80664 86.5045 4.80664C89.6556 4.80664 92.4059 6.58192 93.4806 9.75726H89.7373C89.0634 8.481 88.013 7.85691 86.5122 7.85691C84.2149 7.85691 82.5558 9.60666 82.5558 12.0328C82.5558 14.459 84.2813 16.2573 86.5811 16.2573C87.9569 16.2573 88.9064 15.7072 89.7309 14.4322H93.4818C92.3319 17.5323 89.8317 19.3075 86.6066 19.3075Z" fill="#A165FD" />
            <path d="M95.2448 19.2473V0.0561523H98.6996V19.2473H95.2448Z" fill="#A165FD" />
            <path d="M107.225 19.2934C103.183 19.2934 99.8887 16.0504 99.8887 12.057C99.8887 8.09034 103.183 4.82056 107.2 4.82056C111.367 4.82056 114.586 8.03929 114.586 12.1565C114.581 16.0568 111.237 19.2934 107.225 19.2934ZM107.225 7.86572C105.055 7.86572 103.232 9.73672 103.232 12.0072C103.232 14.3785 105.004 16.2495 107.25 16.2495C109.445 16.2495 111.242 14.3785 111.242 12.057C111.237 9.76224 109.44 7.86572 107.225 7.86572Z" fill="#A165FD" />
            <path d="M125.361 19.2473V17.4822C124.062 18.7585 122.971 19.1962 121.23 19.1962C117.88 19.1962 115.75 17.2487 115.75 12.1768V4.86255H119.205V11.508C119.205 15.3266 120.269 16.0273 121.932 16.0273C123.101 16.0273 124.035 15.5334 124.556 14.7025C124.945 14.0529 125.101 13.171 125.101 11.2477V4.86638H128.556V19.2473H125.361Z" fill="#A165FD" />
            <path d="M141.049 19.2473V17.0675C139.749 18.5403 138.4 19.1529 136.559 19.1529C132.706 19.1529 129.738 16.0375 129.738 12.0135C129.738 7.93963 132.73 4.74897 136.534 4.74897C138.251 4.74897 139.823 5.3871 140.78 6.44257V0H144.244V19.2473H141.049ZM137.001 7.73798C134.767 7.73798 133.025 9.5541 133.025 11.8603C133.025 14.2648 134.792 16.1549 137.074 16.1549C139.283 16.1549 141.03 14.3146 141.03 11.9586C141.025 9.58345 139.283 7.74308 137.001 7.74308V7.73798Z" fill="#A165FD" />
            <path d="M6.28687 24H2.57167L5.27352 17.842L0 4.80371H3.94876L7.13941 14.1294L10.6211 4.80371H14.5953L6.28687 24Z" fill="white" />
            <path d="M25.9774 19.1948V17.4808C24.6781 18.7571 23.5869 19.1948 21.8461 19.1948C18.4959 19.1948 16.3658 17.2473 16.3658 12.1754V4.80371H19.8207V11.5054C19.8207 15.3239 20.8851 16.0259 22.5481 16.0259C23.7171 16.0259 24.6513 15.532 25.1721 14.7011C25.5549 14.0515 25.717 13.5065 25.717 11.5845V4.80371H29.1719V19.1948H25.9774Z" fill="white" />
            <path d="M49.6645 19.1948V11.6355C49.6645 8.75247 48.5733 7.97267 46.9627 7.97267C45.0406 7.97267 44.0541 9.01282 44.0541 12.4396V19.1948H40.603V11.6355C40.603 8.80479 39.6152 7.97267 37.7953 7.97267C35.7954 7.97267 34.9875 9.03835 34.9875 12.4396V19.1948H31.5314V4.80371H34.7016V6.49221C35.715 5.2721 36.7538 4.80371 38.4423 4.80371C40.4167 4.80371 41.6891 5.48013 42.6757 7.03845C43.8447 5.48013 45.2729 4.80371 47.2996 4.80371C51.0914 4.80371 53.1181 6.90827 53.1181 11.2208V19.1948H49.6645Z" fill="white" />
            <path d="M68.4399 13.4589H57.8469C58.2425 15.118 59.6745 16.1505 61.6259 16.1505C62.9838 16.1505 63.7955 15.7804 64.6392 14.7926H68.2203C67.3065 17.608 64.4656 19.1638 61.6999 19.1638C57.5265 19.1638 54.2912 16.0523 54.2912 12.0512C54.2841 11.1022 54.4657 10.1614 54.8256 9.28327C55.1854 8.40518 55.7163 7.60743 56.3873 6.93641C57.0584 6.26539 57.8561 5.73451 58.7342 5.37465C59.6123 5.0148 60.5532 4.83316 61.5021 4.84029C65.5772 4.84029 68.5662 7.95182 68.5662 12.1992C68.5741 12.6226 68.5317 13.0455 68.4399 13.4589ZM61.548 7.85227C59.5966 7.85227 58.2642 8.81585 57.7448 10.5694H65.3271C64.9084 8.81585 63.5747 7.85227 61.5493 7.85227H61.548Z" fill="white" />
            <path d="M73.9779 19.1214C74.9387 19.1214 75.7175 18.3426 75.7175 17.3819C75.7175 16.4212 74.9387 15.6423 73.9779 15.6423C73.0172 15.6423 72.2384 16.4212 72.2384 17.3819C72.2384 18.3426 73.0172 19.1214 73.9779 19.1214Z" fill="#A165FD" />
          </svg>
        )}
        <button
          className="btn btn-primary btn-color-white justify-start gap-2 font-semibold text-nowrap"
          style={{padding: 10, overflow: 'hidden', width: '100%'}}
          onClick={createOrder}
        >
          <Icon icon="add" />
          {t('orders.new_order')}
        </button>
      </div>

      <div
        className={clsx('collapse-btn shadow z-[1000]', !collapse && 'active')}
        style={{opacity}}
        onMouseEnter={() => setOpacity(1)}
        onMouseLeave={() => setTimeout(() => setOpacity(0.3), 1500)}
        onClick={onCollapse}
      >
        <Icon className="cursor-pointer text-2xl color-gray-400" icon="chevron_right" />
      </div>

      <div className="menu overflow-scroll scrollbar-hide">
        <div className="flex flex-col gap-4 items-stretch w-full">

          <div className={!collapse && 'menu-block'}>
            {has_perms('orderrequest') && (
              <LinkWrapper
                collapse
                route="orders"
                icon="list_alt"
                text={t('aside.menu.orders')}
                isSection={false}
              />
            )}
            {has_perms('inventoryschedule') && (
              <LinkWrapper
                collapse
                route="calendar"
                icon="calendar_month"
                text={t('aside.menu.schedules')}
                isSection={false}
              />
            )}
            <Popover
              content={<NotificationPopUp hide={hide} />}
              trigger="click"
              open={open}
              arrow={false}
              onOpenChange={handleOpenChange}
              visible={popoverVisible}
              onVisibleChange={setPopoverVisible}
              placement="right"
              overlayInnerStyle={{padding: 0, marginLeft: '20px', marginTop: '10px'}}
            >
              <div onClick={handleLinkClick} className={clsx('menu-item mb-2')}>
                <Icon className="menu-item-icon" icon="notifications" />
                <div className="menu-item-name">{t('settings.sections.notifications')}</div>
              </div>
            </Popover>
          </div>

          {(has_perms('view_client') || has_perms('inventorygroup') || has_perms('inventoryset') || has_perms('view_service')) && (
            <div className={!collapse && 'menu-block'}>
              {has_perms('inventorygroup') &&
                <LinkWrapper
                  route="groups"
                  icon="inventory_2"
                  text={t('aside.menu.groups')}
                  isSection={true}
                  collapse
                  subRoutes={[
                    has_perms('inventorygroup') && {
                      route: 'sales',
                      icon: 'storefront',
                      text: t('aside.menu.shop')
                    },
                    has_perms('inventoryset') && {
                      route: 'inventory-sets',
                      icon: 'dataset',
                      text: t('aside.menu.sets')
                    },
                    has_perms('view_service') && {
                      route: 'services',
                      icon: 'design_services',
                      text: t('aside.menu.services')
                    }
                  ]}
                />}
              {has_perms('view_client') && (
                <LinkWrapper
                  collapse={true}
                  route="clients"
                  icon="group"
                  text={t('aside.menu.clients')}
                />
              )}
            </div>
          )}

          {(has_perms('metrics') || has_perms('inventoryinventorization')) && (
            <div className={!collapse && 'menu-block'}>
              {has_perms('metrics') && (
                <LinkWrapper
                  collapse={true}
                  route="reports"
                  icon="analytics"
                  text={t('aside.menu.metrics')}
                />
              )}
              {has_perms('inventoryinventorization') && (
                <LinkWrapper
                  collapse={true}
                  route="inventorization"
                  icon="inventory"
                  text={t('aside.menu.inventorization')}
                />
              )}
              {/* {has_perms('maintenance') && linkWrapper('maintenance', 'construction', t('aside.menu.maintenance'))} */}
            </div>
          )}

          <div className={!collapse && 'menu-block menu-block--last'}>
            {profile && profile.is_superuser && (
              <LinkWrapper
                route="integrations"
                icon="toys_and_games"
                text={t('aside.menu.integrations')}
                isSection={true} collapse
                subRoutes={[
                  integrationMap['gps'] && integrationMap['gps'].connected && {
                    route: 'geolocation',
                    icon: 'map',
                    text: t('aside.menu.gps')
                  },
                  integrationMap['wazzup'] && integrationMap['wazzup'].connected && {
                    route: 'integrations/wazzup',
                    icon: 'forum',
                    text: 'Wazzup'
                  },
                  integrationMap['widget'] && integrationMap['widget'].connected && {
                    route: 'integrations/widget',
                    icon: 'widgets',
                    text: t('aside.menu.widget')
                  },
                ].filter(Boolean)}
              />
            )}
            {profile && profile.is_superuser && (
              <LinkWrapper
                collapse={true}
                route="users"
                icon="manage_accounts"
                text={t('aside.menu.users')}
              />
            )}
            <LinkWrapper
              collapse={true}
              route="config"
              icon="page_info"
              text={t('aside.menu.configurations')}
            />
            {profile && profile.is_superuser && (
              <LinkWrapper
                collapse={true}
                route="settings"
                icon="settings"
                text={t('aside.menu.settings')}
              />
            )}
          </div>

          <a
            className={`flex gap-2 ${collapse ? 'justify-center' : 'justify-content-between'} p-3 items-center cursor-pointer border border-gray-700 rounded-lg`}
            href="https://wa.me/+7779479990"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex gap-2 items-center">
              <Icon className={`${collapse} && 'flex items-center'`} icon="help" />
              {!collapse && <div>Support</div>}
            </div>
            {!collapse && <Icon icon="open_in_new" />}
          </a>

          <SubscriptionAside collapse={collapse} />

          <div>
            <CurrentRentalPoint collapse={collapse} />

            {workshift && contentAccess && <WorkshiftTimer collapse={collapse} workshift={workshift} />}

            {profile && (
              <Link to="/profile" className="menu-profile">
                <div className="menu-profile-icon">
                  <Icon icon="person" className="text-base color-white" />
                </div>
                <div className="menu-profile-name">
                  {profile.first_name} {profile.last_name}
                </div>
              </Link>
            )}
          </div>

        </div>
      </div>

    </aside >
  )
}
