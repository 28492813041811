import {ApiAbstract} from "../abstract/api.abstract";
import { ProductResult} from "src/models/manager/website";
import axios from "axios";
import {ListModel} from "src/models/common";

export class NotificationService extends ApiAbstract{
    constructor() {
        super('v1/crm/communication')
    }
    public async list(params?: any): Promise<ListModel<ProductResult>> {
        const response = await axios.get(this.getUrl('notifications'), {params})
        return response.data
    }
}

const notificationService = new NotificationService();
export default notificationService;