import axios from 'axios'
import {BehaviorSubject, Observable, from, of, shareReplay, switchMap} from 'rxjs'
import {axiosResponce} from '../interceptor/axios-response'
import authService, {TokenModel} from "./auth.service"
import tokenService from "./token.service"

export type CountLimit = number | 'unlimited'
export type Limit = false | CountLimit

export interface TenantLimitModel {
  name: string
  rental_point: number | "unlimited"
  staff: number | "unlimited"
  discount: boolean
  metrics: boolean
  inventory_set: boolean
  sale: boolean
  service: boolean
  inventorization: boolean
  custom_fields: boolean
  document: boolean
  bonus: boolean
}

export interface TenantModel {
  name: string,
  address: string,
  logo: string,
  start_at: string,
  end_at: string,
  user: number
  created_at: string
  demo: boolean
  subscription: {
    id: number
    name: 'free' | 'lite' | 'pro'
    extra: string
    limit: TenantLimitModel
  } | undefined
  limits: TenantLimitModel
}

export interface SubscriptionPeriod {
  id: number
  subscription: number
  price: number
  months: number
  days: number
  currency: string
}

export interface Subscription {
  id: number
  name: string
  order: number
  extra: any
  periods: SubscriptionPeriod[]
  limits: {
    rental_point: CountLimit
    staff: CountLimit
    request: Limit
    inventory_group: Limit
    inventory_set: Limit
    sale: boolean
    metrics: boolean
    custom_fields: boolean
    document: boolean
    bonus: boolean
  }
}

class TenantService {
  refreshing: boolean = false

  getTenant$ = new BehaviorSubject<void>(null)
  getTenant = () => this.getTenant$.next()

  listSubscriptions$ = new BehaviorSubject<void>(null)
  listSubscriptions = () => this.listSubscriptions$.next()

  tenant$: Observable<TenantModel> = authService.logged$.pipe(
    switchMap(logged => logged
      ? this.getTenant$.pipe(
        switchMap(() => from(axios.get<TenantModel>('/v1/tenant/account/')).pipe(axiosResponce)),
        shareReplay(1)
      )
      : of(undefined)
    ),
    shareReplay(1)
  )

  subscriptions$: Observable<Subscription[]> = this.getTenant$.pipe(
    switchMap(() => from(axios.get<Subscription[]>('/v1/tenant/subscriptions/')).pipe(axiosResponce)),
    shareReplay(1)
  )

  async createInvoice(payload: any): Promise<any> {
    const response = await axios.post('/v1/tenant/subscriptions/payments/', payload)
    return response.data
  }

  async moveToProduction() {
    const response = await axios.post<TokenModel>('/v1/tenant/account/production/', {})
    tokenService.setToken(response.data as TokenModel)
    window.location.reload()
  }

  async sendLogo(payload: any): Promise<string> {
    const response = await axios.post<string>('/v1/tenant/logo/', payload)
    return response.data
  }
}

const tenantService = new TenantService()
export default tenantService
