import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Dropdown, type MenuProps} from "antd"
import Icon from "../shared/components/material-icon"
import moment from "moment-timezone"
import {EMPTY_LIST, ListModel} from "src/models/common"
import notificationService from "src/services/notification.service"
import {useTranslation} from "react-i18next"
import useOnScreen from "src/hooks/useOnScreen"
import {useNavigate} from "react-router-dom";


const NotificationPopUp = ({hide}) => {

    const [notification, setNotification] = useState<ListModel<any>>(EMPTY_LIST)
    const {t} = useTranslation()
    const [page, setPage] = useState(1)
    const loadMoreRef = useRef<HTMLDivElement>(null)
    const isOnScreen = useOnScreen(loadMoreRef)
    const navigate = useNavigate()

    const items: MenuProps["items"] = [
        {
            key: 'inventory',
            style: {
                display: "flex",
                flexDirection: "row",
            },
            expandIcon: () => null,
            onClick: () => { },
            label: (
                <div className="flex gap-2 hover:text-primary" style={{paddingLeft: -12}}>
                    <Icon icon="check_box" style={{marginLeft: -4}} />
                    {'Отметить все как прочитанное'}
                </div>
            ),
        },
        {
            key: 'archive',
            style: {
                display: "flex",
                flexDirection: "row",
            },
            expandIcon: () => null,
            onClick: () => { },
            label: (
                <div className="flex gap-2 hover:text-primary" style={{paddingLeft: -12}}>
                    <Icon icon="archive" style={{marginLeft: -4}} />
                    {'Архивировать все'}
                </div>
            ),
        }
    ]

    const start_at = useMemo(() => {
        return moment().subtract(1, 'months').startOf('month').toISOString()
    }, [])

    const fetchNotifications = async (page) => {
        const response = await notificationService.list({start_at, page})
        setNotification(prevNotification => ({
            ...response,
            results: [...prevNotification.results, ...response.results],
        }))
    }

    const getPage = (model: string, id: number) => {
        switch (model) {
            case 'orderrequest':
                navigate(`orders/${id}/all`)
                hide()
                break
            case 'inventorymaintenance':
                hide()
                break
            default:
                console.warn("Unknown model:", model)

        }
    }

    const resetPage = () => {
        setPage(1)
        setNotification(EMPTY_LIST)
    }

    useEffect(() => {
        fetchNotifications(page)
    }, [page])

    useEffect(() => {
        if (isOnScreen && notification.next !== false && !!notification.next) {
            setPage(prevPage => prevPage + 1)
        }
    }, [isOnScreen])

    const handleHide = () => {
        hide()
        resetPage()
    }

    const hadleClose = () => {
        navigate('orders/all')
        hide()
    }

    return (
        <div className="w-[500px] h-[600px] overflow-y-scroll font-sans">
            <div className={`flex p-4 w-full items-center justify-between ${notification.results.length === 0 && 'border-b'} border-gray-100`}>
                <h2 className="text-lg font-semibold text-gray-800 flex ">Уведомления</h2>
                <div className="flex gap-3">
                    <Dropdown overlayStyle={{zIndex: 10000}} menu={{items}} placement="bottomLeft" trigger={["click", "click"]} arrow={false}>
                        <button className="gap-2 text-nowrap text-gray-400">
                            <Icon icon="more_horiz" />
                        </button>
                    </Dropdown>
                    <button className="gap-2 text-nowrap text-gray-400"
                        onClick={handleHide}>
                        <Icon icon="close" />
                    </button>
                </div>
            </div>
            {notification.results.length > 0 ? notification.results.map((item, index) => {
                const currentDate = moment(item.created_at).locale('ru').format('DD MMM YYYY')
                const previousDate = index > 0 ? moment(notification.results[index - 1].created_at).locale('ru').format('DD MMM YYYY') : null
                const isToday = moment(item.created_at).isSame(moment(), 'day')
                const showDateDivider = (!previousDate || currentDate !== previousDate) && !isToday
                const order = item.model === 'orderrequest'
                return (
                    <div key={`${item}-${index}`} className="cursor-pointer">
                        {showDateDivider && (
                            <div className="flex items-center text-xs text-gray-400">
                                <div className="flex-grow border-t border-gray-100"></div>
                                <span className="px-2">{currentDate}</span>
                                <div className="flex-grow border-t border-gray-100"></div>
                            </div>
                        )}
                        <div className={`p-3 flex items-center w-full ${!showDateDivider && index !== 0 ? 'border-t border-gray-100' : ''}`}
                            onClick={() => getPage(item.model, item.object_id)}>
                            <div className={`h-6 w-0.5 ${order && 'bg-alert'}`}></div>
                            <div className={`pl-3.5 flex-1`}>
                                <div className="flex gap-2">
                                    <div className="text-sm font-semibold text-black">Cрок аренды истек!:</div>
                                    <div className="text-sm text-gray-400">{item.body}</div>
                                </div>
                                <div className="text-xs text-gray-400 mt-1">
                                    {moment(item.created_at).locale('ru').format('DD MMM YYYY HH:mm')}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }) :
                <div className="px-4 pt-3 flex flex-col gap-7">
                    <div>
                        <p className="text-black font-medium">Нет новых уведомлений</p>
                        <span className="text-gray-400">Вы в курсе всех текущих событий. Как только появится что-то новое, мы сразу сообщим</span>
                    </div>
                    <button className="w-full btn text-gray-400 hover:text-gray-500 border border-gray-100 hover:border-gray-200"
                        onClick={hadleClose}>
                        <Icon icon={'open_in_new'} /> <span className="ml-1.5">Перейти к арендам</span>
                    </button>
                </div>
            }
            <div ref={loadMoreRef}></div>
        </div>
    )
}

export default NotificationPopUp